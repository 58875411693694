/* Section A css starting */
.sectionA {
    min-height: 89vh;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3rem 5%;
}

.section_img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
    z-index: -1;
}

/* .hero1Con {
    border: 1px solid black;
} */

.hero1Con h1 {
    font-family: 'Futura';
    font-size: 12vw;
    font-style: italic;
    font-weight: 400;
    line-height: 10vw;
    text-align: left;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 2vw;
}

.ml {
    margin-left: 2.2vw;
}

.hero1Con h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.45vw;
    line-height: 1.8vw;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-left: 9px;
}

.arrowDownCon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrowDownCon img {
    height: 5rem;
}

.mt3rem {
    margin-top: 3rem;
}

/* Section A css Ending */


/* Section B css starting */
.sectionB {
    background: black;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
}

.sectionB h1 {
    font-family: 'Playfair Display';
    font-style: normal;
    /* font-size: 45px; */
    font-size: 3vw;
    font-weight: 400;
    line-height: 4vw;
    text-align: center;
    color: #FFFFFF;
}

.sectionB h5 {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 400;
    /* font-size: 19px; */
    font-size: 1.27vw;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 20px;
}

/* Section B css starting */


/* Section C css Ending */
.sectionC {
    background: #1E1E1E;
    /* min-height: 200vh; */
    width: 100%;
    padding: 3rem 5%;
    padding-top: 5rem;
    border-top: 2px solid #AF73FC;
    border-bottom: 2px solid #AF73FC;
}

.sectionC h1 {
    font-family: 'Futura2';
    /* font-size: 7rem; */
    /* line-height: 100px; */
    font-size: 7.5vw;
    line-height: 6.5vw;
    letter-spacing: -0.05em;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 3rem;
}

.sectionC h2 {
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.miniContainer {
    padding: 0 15%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    padding-bottom: 4rem;
}

.miniContainer2 {
    /* padding: 0 15%; */
}

.miniContainer2 h1 {
    font-family: 'Futura2';
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    text-align: start;
    color: #FFFFFF;
    margin-bottom: 2rem;
}

.teamContainer {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 3rem;
}

.teamContainer>div {
    /* border: 1px solid white; */
    width: 28%;
    border-radius: 20px;
}

.card_img {
    width: 100%;
    height: 25rem;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
}

.card_inner_img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
    border-radius: 15px;
}

.icon {
    height: 4rem;
    width: 4rem;
}

.width27 {
    width: 27rem;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.box1 {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.para {
    width: 20rem;
}

.para h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #AF73FC;
    margin-bottom: 10px;
    text-align: center;
}

.para h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 28px; */
    color: #FFFFFF;
    text-align: justify;
}

.box2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}

.box2MiniCon {
    width: 55%;
    min-height: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box2MiniCon img {
    width: 100%;
}

.box3 {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Section C css Ending */


/* Section D css Ending */
.sectionD {
    background: rgba(217, 217, 217, 0.1);
    min-height: 100vh;
    width: 100%;
    padding: 3rem 5%;

}

.sectionD>h1 {
    font-family: 'avenirBold', sans-serif;
    font-style: normal;
    font-weight: 900;
    /* font-size: 40px; */
    font-size: 3.4vw;
    /* line-height: 82px; */
    text-align: center;
    color: #FFFFFF;
}

.sectionD>h5 {
    font-family: 'avenirRegular';
    font-style: normal;
    font-weight: 500;
    /* font-size: 22px; */
    font-size: 1.8vw;
    /* line-height: 48px; */
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 4rem;
}

/* .sectionDCon {
    border: 1px solid white;
} */

.sectionDBox {
    border: 1px solid #313131;
    height: 26rem;
    border-radius: 4rem;
    position: relative;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    padding: 0 3.5%;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), transparent);
    transition-property: all;
    transition-duration: 0.3s;
}

.sectionDBox:hover {
    border: 1px solid #af73fccc;
    background: linear-gradient(45deg, rgba(0, 0, 0, 1), transparent);
}

.sectionDBox img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
    z-index: -1;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-radius: 4rem;
}

.para2 {
    width: 30rem;
    margin-bottom: 3rem;
}

.para2 h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    margin-bottom: 15px;
    text-align: start;
    transition-property: all;
    transition-duration: 0.3s;
}

.sectionDBox:hover .para2 h4 {
    color: #AF73FC;
}

.para2 h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 28px; */
    color: #FFFFFF;
    text-align: start;
    margin: 0;
}

.textAlign {
    text-align: center;
    width: 100%;
}

.SectionDConBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 2rem;
}

.SectionDConBottom h5 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    /* line-height: 28px; */
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

.SectionDConBottom img {
    height: 20px;
}

.SectionDConBottom button {
    border: none;
    background: none;
    background: #AF73FC;
    border-radius: 8px;
    padding: 10px 25px;
    cursor: pointer;
    transition-property: all;
    transition-duration: 0.3s;
}

.SectionDConBottom button:hover {
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.SectionDConBottom button h5 {
    font-size: 13px;
}

.partnersCon {
    margin: 2rem 0;
    margin-bottom: 8rem;
}

.partnersCon h4 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 40px;
}

.partenersList {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partenersList img {
    max-height: 10rem;
    transition-property: all;
    transition-duration: 0.3s;
    filter: grayscale(100%) brightness(100%);
}

.partenersList img:hover {
    transform: scale(1.1);
    filter: none;
}

.sliderCon {
    border-top: 2px solid #ffffff4a;
    border-bottom: 2px solid #ffffff4a;
    padding: 20px 30px;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10%;
}

.sliderItem {
    display: flex;
    gap: 2rem;
}

.sliderImgCon {
    height: 8rem;
    width: 8rem;
    position: relative;
}

.sliderImgCon img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
    z-index: -1;
}

.sliderItem h5 {
    max-width: 18rem;
    font-size: 17px;
}

.sliderBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.pagination {
    display: flex;
    align-items: center;
    gap: 5px;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
}

.navigation {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navigation button {
    border: none;
    background: none;
}

.pulsingArrow {
    opacity: 1;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        opacity: 0.5;
        transform: translateY(10px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

/* Section D css Ending */


@media (max-width: 1200px) {
    .para h4 {
        font-size: 24px;
    }

    .para h5 {
        font-size: 16px;
    }

    .para2 h4 {
        font-size: 26px;
    }

    .para2 h5 {
        font-size: 15px;
    }

    .miniContainer {
        padding: 0 10%;
    }

    .sectionDBox {
        height: 22rem;
    }

    .sliderItem {
        gap: 1rem;
    }

    .sliderItem h5 {
        max-width: 13rem;
    }

    .sliderCon {
        grid-template-columns: 50% 50%;
        gap: 0;
    }
}

@media (max-width: 1200px) {
    .sectionDBox {
        height: 18rem;
    }
}

@media (max-width: 1000px) {
    .para {
        width: 16rem;
    }

    .para h4 {
        font-size: 20px;
    }

    .para h5 {
        font-size: 15px;
    }

    .para2 {
        width: 22rem;
    }

    .para2 h4 {
        font-size: 18px;
    }

    .para2 h5 {
        font-size: 12px;
    }

    .sectionDBox {
        height: 15rem;
    }

    .hero1Con h5 {
        font-size: 2vw;
        line-height: 2.5vw;
    }

    .arrowDownCon img {
        height: 4rem;
    }

    .sectionB h1 {
        font-size: 4vw;
        line-height: 5vw;
    }

    .sectionB h5 {
        font-size: 1.8vw;
        margin-top: 10px;
    }

    .miniContainer {
        padding: 0 5%;
    }

    .sectionDBox {
        border-radius: 2.5rem;
        padding: 0 5%;
    }

    .sectionDBox img {
        border-radius: 2.5rem;
    }

    .sliderCon {
        padding: 20px 0px;
    }

    .sliderItem {
        flex-direction: column;
        max-width: 13rem;
    }

    .sliderItem h5 {
        font-size: 14px;
    }

    .teamContainer {
        gap: 1.5rem;
    }

    .card_img {
        height: 17rem;
    }
}

@media (max-width: 800px) {
    .hero1Con h5 {
        font-size: 2.5vw;
        line-height: 2.8vw;
    }

    .sectionA {
        min-height: 70vh;
        padding: 2rem 5%;
    }

    .para {
        width: 12rem;
    }

    .para h4 {
        font-size: 17px;
    }

    .para h5 {
        font-size: 13px;
    }

    .para2 {
        width: 17rem;
        margin-bottom: 0rem;
    }

    .para2 h4 {
        margin-bottom: 8px;
    }

    .para2 h4 {
        font-size: 16px;
    }

    .para2 h5 {
        font-size: 13px;
    }

    .box2MiniCon {
        min-height: 10rem;
        width: 50%;
    }

    .sectionDBox {
        border-radius: 2rem;
        background: linear-gradient(45deg, #000000, transparent);
    }

    .sectionDBox img {
        border-radius: 2rem;
    }

    .partenersList img {
        max-height: 4rem;
    }

    .teamContainer {
        gap: 1rem;
    }

    .card_img {
        height: 13rem;
    }
}

@media (max-width: 600px) {
    .hero1Con h5 {
        font-size: 2.5vw;
        line-height: 2.8vw;
    }

    .sectionA {
        min-height: 47vh;
        padding: 1rem 5%;
    }

    .arrowDownCon img {
        height: 3rem;
    }

    .para {
        width: 12rem;
    }

    .para h4 {
        font-size: 17px;
    }

    .para h5 {
        font-size: 13px;
    }

    .para2 {
        width: 18rem;
    }

    .para2 h4 {
        margin-bottom: 8px;
    }

    .para2 h4 {
        font-size: 16px;
    }

    .para2 h5 {
        font-size: 13px;
    }

    .box2MiniCon {
        min-height: 10rem;
        width: 50%;
    }

    .sectionDBox {
        border-radius: 1.5rem;
        height: 12rem;
    }

    .sectionDBox img {
        border-radius: 1.5rem;
    }

    .sectionB {
        min-height: 50vh;
    }

    .miniContainer {
        padding: 0;
    }

    .sectionC {
        padding: 2rem 5%;
    }

    .sliderItem h5 {
        max-width: 9rem;
    }

    .SectionDConBottom {
        gap: 10px;
    }

    .SectionDConBottom h5 {
        font-size: 15px;
    }

    .SectionDConBottom button h5 {
        font-size: 11px;
    }

    .partnersCon h4 {
        font-size: 18px;
    }

    .partenersList img {
        max-height: 3rem;
    }

    .sectionD>h1 {
        font-size: 4.4vw;
    }

    .sectionD>h5 {
        font-size: 2.8vw;
    }

    .teamContainer {
        gap: 1.5rem;
    }

    .card_img {
        height: 11rem;
    }
}

@media (max-width: 480px) {
    .hero1Con h5 {
        font-size: 3vw;
        line-height: 3vw;
    }

    .sectionA {
        min-height: 25vh;
        gap: 1rem;
    }

    .arrowDownCon img {
        height: 2rem;
    }

    .para {
        width: 8rem;
    }

    .para h4 {
        font-size: 13px;
    }

    .para h5 {
        font-size: 10px;
    }

    .para2 {
        width: 18rem;
    }

    .para2 h4 {
        margin-bottom: 8px;
    }

    .para2 h4 {
        font-size: 13px;
    }

    .para2 h5 {
        font-size: 10px;
    }

    .box2 {
        margin: 1rem 0;
    }

    .box2MiniCon {
        min-height: 10rem;
        width: 50%;
    }

    .sectionDBox {
        border-radius: 1rem;
        height: 9rem;
    }

    .sectionDBox img {
        border-radius: 1rem;
    }

    .sectionB {
        min-height: 30vh;
    }

    .miniContainer {
        padding: 0;
    }

    .sectionC {
        padding: 2rem 5%;
    }

    .sectionC h1 {
        margin-bottom: 2rem;
    }

    .sliderItem h5 {
        max-width: 9rem;
        font-size: 11px;
    }

    .sliderCon {
        display: flex;
        justify-content: space-around;
    }

    .SectionDConBottom button h5 {
        font-size: 10px;
    }

    .SectionDConBottom button {
        padding: 8px 22px;
    }

    .partnersCon {
        margin: 3rem 0;
        margin-bottom: 3rem;
    }

    .partnersCon h4 {
        font-size: 14px;
    }

    .icon {
        height: 3rem;
        width: 3rem;
    }

    .width27 {
        width: auto;
    }

    .sectionC h2 {
        font-size: 20px;
    }

    .miniContainer {
        gap: 2rem;
    }

    .teamContainer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .teamContainer>div {
        width: 13rem;
    }

    .card_img {
        height: 18rem;
    }
}