.navbar {
    height: 5rem;
    border: 1px solid black;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: black;
}

.logo {
    font-family: 'Futura2';
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: italic;
    letter-spacing: 2px;
    font-size: 20px;
}

.navRight {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navRight h5 {
    font-size: 14px;
    cursor: pointer;
}

.navRight h5:hover {
    text-decoration: underline;
}

@media (max-width: 600px) {
    .navbar {
        height: 3rem;
    }
}

@media (max-width: 480px) {
    .logo {
        font-size: 14px;
    }

    .navRight {
        gap: 10px;
    }

    .navRight h5 {
        font-size: 10px;
    }
}