.footer {
    min-height: 40vh;
    background: black;
    padding: 6rem 5%;
    border-top: 2px solid #AF73FC;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footer h5 {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 17px;
}

.footer h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.colored {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: #AF73FC;
    margin-bottom: 30px !important;
}

.flexAlign {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.phone {
    font-size: 15px !important;
    font-weight: 500 !important;
    margin: 0 !important;
}

.footerRight {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    gap: 2rem;
    width: fit-content;
}

.flexEnd {
    display: flex;
    justify-content: end;
}

.form {
    width: fit-content;
    height: 2.5rem;
    background: #1C1C1C;
    border-radius: 10px;
}

.form input {
    height: 100%;
    padding: 0 20px;
    width: 14rem;
    outline: none;
    border: none;
    background: none;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    margin-top: -1px;
}

.form input::placeholder {
    color: rgba(255, 255, 255, 0.692);
}

.form button {
    height: 99%;
    border: none;
    outline: none;
    background: none;
    background: #AF73FC;
    border-radius: 0 10px 10px 0;
    padding: 0 25px;
    cursor: pointer;
}

.textAlign3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
}

.alignEnd {
    text-align: end;
    margin: 0 !important;
}

.footerNav h5 {
    cursor: pointer;
}

.footerNav h5:hover {
    text-decoration: underline;
}

@media (max-width:1200px) {
    .footer {
        grid-template-columns: 20% 30% 50%;
    }
}

@media (max-width:800px) {
    .footer h5 {
        font-size: 14px;
    }

    .footer h1 {
        font-size: 17px;
    }

    .phone {
        font-size: 13px !important;
    }

    .flexAlign svg {
        height: 15px;
    }

    .form {
        height: 2rem;
    }

    .form input {
        padding: 0 10px;
        width: 10rem;
        font-size: 10px;
    }

    .form button {
        padding: 0 12px;
        font-size: 12px;
    }
}

@media (max-width:600px) {
    .footer {
        padding: 3rem 5%;
        display: flex;
        flex-direction: column;
    }

    .footer h5 {
        text-align: center;
    }

    .footer h1 {
        text-align: center;
    }

    .flexEnd {
        justify-content: center;
        margin-top: 1rem;
    }

    .footerRight {
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .flexAlign {
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .footer h5 {
        font-size: 12px;
    }

    .footer h1 {
        font-size: 14px;
    }

    .phone {
        font-size: 12px !important;
    }

    .flexAlign svg {
        height: 15px;
    }

    .form {
        height: 2rem;
    }

    .form input {
        padding: 0 10px;
        width: 10rem;
        font-size: 10px;
    }

    .form button {
        padding: 0 12px;
        font-size: 12px;
    }
}