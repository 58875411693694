.popup {
    background-color: #00000057;
    /* margin-left: 265px; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.card {
    background: #1E1E1E;
    padding: 1.5rem;
    border-radius: 8px;
    width: fit-content;
    text-align: left;
    border: none;
    position: relative;
    /* width: 36rem; */
    height: 40rem;
}

.close {
    cursor: pointer;
    background: #2C2C2C;
    border-radius: 3px;
    padding: 3px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close svg {
    height: 1rem;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.body {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-top: 1rem;
}

.body h1 {
    font-family: 'Futura2';
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.body h5 {}

.card_img {
    width: 18rem;
    height: 18rem;
    position: relative;
    margin-bottom: 10px;
}

.card_inner_img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    color: transparent;
    border-radius: 15px;
}

.width18 {
    width: 18rem;
}

@media (max-width: 600px) {
    .body{
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .card{
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .width18{
        width: 100%;
    }
    .card_img{
        width: 100%;
    }
}