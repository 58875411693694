@font-face {
  font-family: 'Futura';
  src: url('../public/fonts/FuturaPTExtraBold.otf');
}

@font-face {
  font-family: 'Futura2';
  src: url('../public/fonts/FuturaPTHeavy.otf');
}

@font-face {
  font-family: 'avenirBold';
  src: url('../public/fonts/avenir/AvenirNextLTPro-Bold.otf');
}

@font-face {
  font-family: 'avenirRegular';
  src: url('../public/fonts/avenir/AvenirNextLTPro-Regular.otf');
}
@font-face {
  font-family: 'new changed font';
  src: url('../public/fonts/font.woff');
}

@font-face {
  font-family: 'OPTIFutura-ExtraBlack';
  src: url('assets/fonts/OPTIFutura-ExtraBlackCond.otf') format('truetype');
}

@font-face {
  font-family: 'OPTIFuturaDemiBold';
  src: url('assets/fonts/OPTIFuturaDemiBold.otf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

body {
  margin: 0;
  background: #1E1E1E;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

a {
  text-decoration: none;
}